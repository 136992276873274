var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "box"
  }, [_vm._m(0), _c('div', {
    staticClass: "popupContArea"
  }, [_c('ul', {
    staticClass: "cont"
  }, [_c('li', [_vm._m(1), _c('ul', {
    staticClass: "contArea"
  }, [_c('li', [_c('p', [_vm._v("환자번호")]), _c('span', [_vm._v(_vm._s(_vm.targetInfo.patientSn))])]), _c('li', [_c('p', [_vm._v("이름")]), _c('span', [_vm._v(_vm._s(_vm.targetInfo.name))])]), _c('li', [_c('p', [_vm._v("생년월일")]), _c('span', [_vm._v(_vm._s(_vm.targetInfo.birth))])]), _c('li', [_c('p', [_vm._v("성별")]), _c('span', [_vm._v(_vm._s(_vm.targetInfo.sex === 0 ? '남' : _vm.targetInfo.sex === 1 ? '여' : ''))])])])]), _c('li', [_vm._m(2), _c('ul', {
    staticClass: "contArea"
  }, [_c('li', [_c('p', [_vm._v("분석 유형")]), _c('span', [_vm._v(_vm._s(_vm.getXrayType()))])]), _vm._m(3), _c('li', [_c('p', [_vm._v("촬영일")]), _c('span', [_vm._v(_vm._s(_vm.pictureDt))])])])])]), _c('div', {
    staticClass: "textArea"
  }, [!_vm.targetInfo.no ? _c('p', [_vm._v("이 환자는 등록되지 않은 환자입니다.")]) : _vm._e(), _c('p', [_vm._v("해당 환자로 분석하시겠습니까?")])]), _c('div', {
    staticClass: "btnW popBtn"
  }, [_c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn default",
    on: {
      "click": function ($event) {
        return _vm.$emit('closePopup');
      }
    }
  }, [_vm._v(_vm._s(_vm.CONST_CLOSE))]), _c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn solid",
    on: {
      "click": function ($event) {
        return _vm.registPatient();
      }
    }
  }, [_vm._v("분석")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popTitle popTitleArea"
  }, [_c('p', [_c('i', {
    staticClass: "micon"
  }, [_vm._v("info")]), _vm._v("분석 정보 확인")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "titArea"
  }, [_c('p', {
    staticClass: "tit"
  }, [_vm._v("환자 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "titArea"
  }, [_c('p', {
    staticClass: "tit"
  }, [_vm._v("분석 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "noticeText"
  }, [_c('p'), _c('em', [_vm._v("※Whole spine PA X-ray 만 분석 가능")])]);

}]

export { render, staticRenderFns }